import React, { useState, useEffect, useContext } from "react";
import { Button } from "@material-ui/core";
import { UIContext } from "../../contexts/UIContext";
import coverImage from "../../assets/images/cover_img.png";
import axiosInstance from "../../helpers/axiosInstance";
import classes from "./StudentDetails.module.css";
import UpdateStudentDetailsModal from "../Dialogs/UpdateStudentDetailsModal";
import Toast from "../../components/ui/toast/Toast";
import defaultImage from "../../assets/images/defaultimage.png";
import CF from "../../utils/CommonFunction";
import Linkify from "react-linkify";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";

const StudentDetails = ({ teacherId }) => {
  const { setLoading } = useContext(UIContext);
  const [editTeacherInfo, setEditTeacherInfo] = useState({});
  const [student, setStudent] = useState({
    firstName: "",
    lastName: "",
    profileImage: "",
    bio: "",
  });

  const [teacherData, setTeacherData] = useState({
    firstName: "",
    lastName: "",
    bio: "",
  });

  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [toast, setToast] = useState();

  useEffect(() => {
    getStudentDetails();
  }, []);

  const getStudentDetails = () => {
    // setLoading(true);
    axiosInstance
      .get("/student/details")
      .then((res) => {
        setStudent(res.data.student);
        const { firstName, lastName, bio } = res.data.student;
        setTeacherData({
          firstName,
          lastName,
          bio: bio || "",
        });
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const handleTeacherDataChange = (e) => {
    setTeacherData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  //Validation for Student profile update
  const validateUpdateProfileDetail = () => {
    let message = [];
    const nameRegex = /^[A-Za-z\s]+$/; // Regex to allow only alphabet characters and spaces

    // Check if first name is empty
    if (teacherData.firstName === "") {
      message.push("Please enter first name.");
    }
    // Check if first name contains only letters
    else if (!nameRegex.test(teacherData.firstName)) {
      message.push(
        "First name can only contain letters. Numbers and special characters are not allowed."
      );
    }

    // Check if last name is empty
    if (teacherData.lastName === "") {
      message.push("Please enter last name.");
    }
    // Check if last name contains only letters
    else if (!nameRegex.test(teacherData.lastName)) {
      message.push(
        "Last name can only contain letters. Numbers and special characters are not allowed."
      );
    }

    if (message.length > 0) {
      // Looping of errors (this still only shows the first error)
      for (let i = 0; i < 1; i++) {
        setToast({
          status: "error",
          title: "Error",
          body: message[i],
        });
      }
    }

    return message.length === 0;
  };

  const updateTeacherDetails = (e) => {
    e.preventDefault();
    const isValid = validateUpdateProfileDetail();
    if (isValid) {
      setUpdateModalOpen(false);
      setLoading(true);
      axiosInstance
        .put("/student/detail", teacherData)
        .then((res) => {
          getStudentDetails();
          setLoading(false);
          setToast(CF.alert.success("Profile details updated successfully"));
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  };

  const changeProfileImage = (e) => {
    const imageFile = e.target.files[0];
    const formData = new FormData();
    formData.append("imageFile", imageFile);
    if (!imageFile.name.match(/\.(png|jpg|jpeg)$/)) {
      setToast(
        "The profile photo must be an image with type png, jpg or jpeg."
      );
      return false;
    }
    setLoading(true);
    axiosInstance
      .put("/student/profile-image", formData)
      .then((res) => {
        setToast(CF.alert.success("Profile Image changed successfully"));
        getStudentDetails();
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const formatBio = (bio) => {
    return bio?.replace(/([a-z])([A-Z])/g, "$1 $2").replace(/(.{30})/g, "$1 ");
  };

  const onEditTeacher = (teacherObject) => {
    setTeacherData((prev) => ({
      ...prev,
      firstName: teacherObject.firstName,
      lastName: teacherObject.lastName,
      bio: teacherObject.bio,
    }));
    setEditTeacherInfo(teacherObject);
    setUpdateModalOpen(true);
  };

  return (
    <div>
      <img src={coverImage} className={classes.coverImage} />
      <div className={classes.details}>
        <div className={classes.top}>
          <div
            style={{
              backgroundImage: `url(${
                student.profileImage.includes("=")
                  ? student.profileImage.split("=")[0]
                  : student.profileImage || defaultImage
              })`,
            }}
            className={classes.profileImage}
          >
            <input
              type="file"
              id="profileImageInput"
              accept="image/*"
              onChange={changeProfileImage}
              hidden
            />
            <label
              htmlFor="profileImageInput"
              className={classes.uploadProfileBtn}
            >
              &#43;
            </label>
          </div>
        </div>
        <div className={classes.mainDetails}>
          <div className={classes.metaDetails}>
            <h2>
              {student.firstName} {student.lastName}
            </h2>
            <p>{student?.institution?.name}</p>
          </div>
          <div className={classes.followingDetails}>
            <div className={classes.followingDetailsItem}>
              <h3>Following</h3>
              <div className={classes.following}>
                <div style={{ marginRight: ".5rem" }}>
                  <h2>{student.following_count}</h2>
                  <label>Teachers</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        {!teacherId && (
          <div style={{ marginBottom: "1rem" }}>
            <Button
              variant="contained"
              startIcon={<EditOutlinedIcon fontSize="small" />}
              style={{
                backgroundColor: "var(--primary-color)",
                borderRadius: "12px",
                height: "40px",
                color: "#fff",
                fontSize: "18px",
                textTransform: "capitalize",
              }}
              onClick={() => onEditTeacher(student)}
            >
              Edit Details
            </Button>
          </div>
        )}
        <h4>About Me</h4>
        <Linkify
          componentDecorator={(decoratedHref, decoratedText, key) => {
            return (
              <a
                key={key}
                href={decoratedHref}
                alt="Link"
                rel="noreferrer"
                target={"_blank"}
                className={classes.links}
              >
                {decoratedText}
              </a>
            );
          }}
        >
          <div className={classes.bio}> {formatBio(student?.bio)}</div>
        </Linkify>
      </div>
      <UpdateStudentDetailsModal
        open={updateModalOpen}
        setOpen={setUpdateModalOpen}
        teacherData={teacherData}
        handleTeacherDataChange={handleTeacherDataChange}
        handleSubmit={updateTeacherDetails}
        editTeacherInfo={editTeacherInfo}
      />
      {toast && <Toast data={toast} hideToast={() => setToast(null)} />}
    </div>
  );
};

export default StudentDetails;
